import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import jwt_decode from 'jwt-decode';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private http: HttpClient) {}
  /**
   * Save a token locally inside the localStorage object
   * @param token The token that has to be saved
   */
  setToken(token: string): void {
    localStorage.setItem(environment.auth.access_token, token);
  }

  /**
   * Get the token that is saved locally inside the localStorage object
   */
  getToken(): string {
    return localStorage.getItem(environment.auth.access_token);
  }

  hasToken(): boolean {
    return !!localStorage.getItem(environment.auth.access_token);
  }

  /**
   * Delete the token that is saved locally inside the localStorage object
   */
  deleteToken(): void {
    localStorage.removeItem(environment.auth.access_token);
  }

  decodeToken(): any {
    const token = this.getToken();

    if (!token) {
      return null;
    }

    return jwt_decode(token);
  }

  logout(): Observable<void> {
    return this.http.get<void>(environment.endpoints.OTHERS.logout);
  }

  isLexusDealer(): boolean {
    return this.decodeToken()?.dealer_number?.startsWith('6');
  }
}
